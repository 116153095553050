import axios from "axios";
import { useState } from "react";
const Addrechargehistory = () => {
  const [INITIATOR_ID, setINITIATOR_ID] = useState("");
  const [CUSTOMER_MOBILE, setCUSTOMER_MOBILE] = useState("");
  const [TYPE, setTYPE] = useState("");
  const [AMOUNT, setAMOUNT] = useState("");
  const [TRANSACTION_DATE, setTRANSACTION_DATE] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [NETWORK, setNETWORK] = useState("");
  const [PLAN, setPLAN] = useState("");
  const [detail, setdetail] = useState("");
  const submitdata = () => {
    const value = {
      INITIATOR_ID: INITIATOR_ID,
      CUSTOMER_MOBILE: CUSTOMER_MOBILE,
      TYPE: TYPE,
      AMOUNT: AMOUNT,
      TRANSACTION_DATE: TRANSACTION_DATE,
      STATUS: STATUS,
      NETWORK: NETWORK,
      PLAN: PLAN,
      detail: detail,
    };
    axios
      .post("http://202.170.204.109:5000/mobilerecharge/insertrechargehistory", value)
      .then((res) => {
        if (res.data !== "error") {
          alert("success");
          setINITIATOR_ID("");
          setCUSTOMER_MOBILE("");
          setTYPE("");
          setAMOUNT("");
          setTRANSACTION_DATE("");
          setSTATUS("");
          setNETWORK("");
          setPLAN("");
          setdetail("");
        }
        else
        {
          alert("error")
        }
      });
  };
  return (
    <div>
      <h1>Add rechargehistory</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setINITIATOR_ID(e.target.value)}
          value={INITIATOR_ID}
          placeholder="Enter INITIATOR_ID"
        />
        <label htmlFor="INITIATOR_ID">INITIATOR_ID</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setCUSTOMER_MOBILE(e.target.value)}
          value={CUSTOMER_MOBILE}
          placeholder="Enter CUSTOMER_MOBILE"
        />
        <label htmlFor="CUSTOMER_MOBILE">CUSTOMER_MOBILE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setTYPE(e.target.value)}
          value={TYPE}
          placeholder="Enter TYPE"
        />
        <label htmlFor="TYPE">TYPE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setAMOUNT(e.target.value)}
          value={AMOUNT}
          placeholder="Enter AMOUNT"
        />
        <label htmlFor="AMOUNT">AMOUNT</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setTRANSACTION_DATE(e.target.value)}
          value={TRANSACTION_DATE}
          placeholder="Enter TRANSACTION_DATE"
        />
        <label htmlFor="TRANSACTION_DATE">TRANSACTION_DATE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setSTATUS(e.target.value)}
          value={STATUS}
          placeholder="Enter STATUS"
        />
        <label htmlFor="STATUS">STATUS</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setNETWORK(e.target.value)}
          value={NETWORK}
          placeholder="Enter NETWORK"
        />
        <label htmlFor="NETWORK">NETWORK</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setPLAN(e.target.value)}
          value={PLAN}
          placeholder="Enter PLAN"
        />
        <label htmlFor="PLAN">PLAN</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setdetail(e.target.value)}
          value={detail}
          placeholder="Enter detail"
        />
        <label htmlFor="detail">detail</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Addrechargehistory;
