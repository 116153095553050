import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Updatedistributors = () => {
  var rx = 0;
  const { state } = useLocation();
  const [DISTRIBUTOR_ID, setDISTRIBUTOR_ID] = useState(state[0]);
  const [DISTRIBUTOR_NAME, setDISTRIBUTOR_NAME] = useState(state[1]);
  // const [PROFIT, setPROFIT] = useState(state[rx++]);
  const [PASSWORD, setPASSWORD] = useState(state[4]);
  const [MOBILE, setMOBILE] = useState(state[5]);
  const [EMAIL, setEMAIL] = useState(state[6]);
  const [BALANCE, setBALANCE] = useState(state[7]);
  const [LOCATION, setLOCATION] = useState(state[8]);
  const submitdata = () => {
    const value = {
      USER_ID: DISTRIBUTOR_ID,
      USER_NAME: DISTRIBUTOR_NAME,
      PASSWORD: PASSWORD,
      MOBILE: MOBILE,
      EMAIL: EMAIL,
      BALANCE: BALANCE,
      LOCATION: LOCATION,
    };
    axios
      .post("http://202.170.204.109:5000/mobilerecharge/updatedistributors", value)
      .then((response) => {
        window.location.replace("/viewdistributors");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  return (
    <div>
      <h1>Update distributor</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setDISTRIBUTOR_ID(e.target.value)}
          value={DISTRIBUTOR_ID}
          placeholder="Enter DISTRIBUTOR_ID"
        />
        <label htmlFor="DISTRIBUTOR_ID">DISTRIBUTOR_ID</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setDISTRIBUTOR_NAME(e.target.value)}
          value={DISTRIBUTOR_NAME}
          placeholder="Enter DISTRIBUTOR_NAME"
        />
        <label htmlFor="DISTRIBUTOR_NAME">DISTRIBUTOR_NAME</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setPROFIT(e.target.value)}
          value={PROFIT}
          placeholder="Enter PROFIT"
        />
        <label htmlFor="PROFIT">PROFIT</label>
      </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setPASSWORD(e.target.value)}
          value={PASSWORD}
          placeholder="Enter PASSWORD"
        />
        <label htmlFor="PASSWORD">PASSWORD</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setMOBILE(e.target.value)}
          value={MOBILE}
          placeholder="Enter MOBILE"
        />
        <label htmlFor="MOBILE">MOBILE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setEMAIL(e.target.value)}
          value={EMAIL}
          placeholder="Enter EMAIL"
        />
        <label htmlFor="EMAIL">EMAIL</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setBALANCE(e.target.value)}
          value={BALANCE}
          placeholder="Enter BALANCE"
        />
        <label htmlFor="BALANCE">BALANCE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setLOCATION(e.target.value)}
          value={LOCATION}
          placeholder="Enter LOCATION"
        />
        <label htmlFor="LOCATION">LOCATION</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updatedistributors;
