
import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Updaterechargehistory = () => {
var rx=0;
const {state}=useLocation();
const [RECHARGE_ID, setRECHARGE_ID] = useState(state[rx++]);
const [INITIATOR_ID, setINITIATOR_ID] = useState(state[rx++]);
const [CUSTOMER_MOBILE, setCUSTOMER_MOBILE] = useState(state[rx++]);
const [TYPE, setTYPE] = useState(state[rx++]);
const [AMOUNT, setAMOUNT] = useState(state[rx++]);
const [TRANSACTION_DATE, setTRANSACTION_DATE] = useState(state[rx++]);
const [STATUS, setSTATUS] = useState(state[rx++]);
const [NETWORK, setNETWORK] = useState(state[rx++]);
const [PLAN, setPLAN] = useState(state[rx++]);
const [detail, setdetail] = useState(state[rx++]);
const submitdata = () => {
 const value={RECHARGE_ID:RECHARGE_ID,INITIATOR_ID:INITIATOR_ID,CUSTOMER_MOBILE:CUSTOMER_MOBILE,TYPE:TYPE,AMOUNT:AMOUNT,TRANSACTION_DATE:TRANSACTION_DATE,STATUS:STATUS,NETWORK:NETWORK,PLAN:PLAN,detail:detail};
axios.post("http://202.170.204.109:5000/mobilerecharge/updaterechargehistory", value).then
    (response=>{
      window.location.replace('/viewrechargehistory')
    })};
return (
<div>
    <h1>Add rechargehistory</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setRECHARGE_ID(e.target.value)}
            value={RECHARGE_ID}
            placeholder="Enter RECHARGE_ID"
          />
          <label htmlFor="RECHARGE_ID">RECHARGE_ID</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setINITIATOR_ID(e.target.value)}
            value={INITIATOR_ID}
            placeholder="Enter INITIATOR_ID"
          />
          <label htmlFor="INITIATOR_ID">INITIATOR_ID</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setCUSTOMER_MOBILE(e.target.value)}
            value={CUSTOMER_MOBILE}
            placeholder="Enter CUSTOMER_MOBILE"
          />
          <label htmlFor="CUSTOMER_MOBILE">CUSTOMER_MOBILE</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setTYPE(e.target.value)}
            value={TYPE}
            placeholder="Enter TYPE"
          />
          <label htmlFor="TYPE">TYPE</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setAMOUNT(e.target.value)}
            value={AMOUNT}
            placeholder="Enter AMOUNT"
          />
          <label htmlFor="AMOUNT">AMOUNT</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setTRANSACTION_DATE(e.target.value)}
            value={TRANSACTION_DATE}
            placeholder="Enter TRANSACTION_DATE"
          />
          <label htmlFor="TRANSACTION_DATE">TRANSACTION_DATE</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setSTATUS(e.target.value)}
            value={STATUS}
            placeholder="Enter STATUS"
          />
          <label htmlFor="STATUS">STATUS</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setNETWORK(e.target.value)}
            value={NETWORK}
            placeholder="Enter NETWORK"
          />
          <label htmlFor="NETWORK">NETWORK</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPLAN(e.target.value)}
            value={PLAN}
            placeholder="Enter PLAN"
          />
          <label htmlFor="PLAN">PLAN</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setdetail(e.target.value)}
            value={detail}
            placeholder="Enter detail"
          />
          <label htmlFor="detail">detail</label>
        </div>

 <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
</div>
)
}
export default Updaterechargehistory;
