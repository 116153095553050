
import axios from "axios";
import { useState } from "react";
const Addtransactions = () => {
const [SENDER_ID, setSENDER_ID] = useState('');
const [RECEIVER_ID, setRECEIVER_ID] = useState('');
const [PREVIOUS_BALANCE, setPREVIOUS_BALANCE] = useState('');
const [AMOUNT, setAMOUNT] = useState('');
const [NEW_BALANCE, setNEW_BALANCE] = useState('');
const [DETAILS, setDETAILS] = useState('');
const [TRANSACTION_DATE, setTRANSACTION_DATE] = useState('');
const submitdata = () => {
 const value={SENDER_ID:SENDER_ID,RECEIVER_ID:RECEIVER_ID,PREVIOUS_BALANCE:PREVIOUS_BALANCE,AMOUNT:AMOUNT,NEW_BALANCE:NEW_BALANCE,DETAILS:DETAILS,TRANSACTION_DATE:TRANSACTION_DATE};
axios.post("http://202.170.204.109:5000/mobilerecharge/inserttransactions", value);
alert("success")
setSENDER_ID('');
setRECEIVER_ID('');
setPREVIOUS_BALANCE('');
setAMOUNT('');
setNEW_BALANCE('');
setDETAILS('');
setTRANSACTION_DATE('');
};
return (
<div>
    <h1>Add transactions</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setSENDER_ID(e.target.value)}
            value={SENDER_ID}
            placeholder="Enter SENDER_ID"
          />
          <label htmlFor="SENDER_ID">SENDER_ID</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setRECEIVER_ID(e.target.value)}
            value={RECEIVER_ID}
            placeholder="Enter RECEIVER_ID"
          />
          <label htmlFor="RECEIVER_ID">RECEIVER_ID</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPREVIOUS_BALANCE(e.target.value)}
            value={PREVIOUS_BALANCE}
            placeholder="Enter PREVIOUS_BALANCE"
          />
          <label htmlFor="PREVIOUS_BALANCE">PREVIOUS_BALANCE</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setAMOUNT(e.target.value)}
            value={AMOUNT}
            placeholder="Enter AMOUNT"
          />
          <label htmlFor="AMOUNT">AMOUNT</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setNEW_BALANCE(e.target.value)}
            value={NEW_BALANCE}
            placeholder="Enter NEW_BALANCE"
          />
          <label htmlFor="NEW_BALANCE">NEW_BALANCE</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setDETAILS(e.target.value)}
            value={DETAILS}
            placeholder="Enter DETAILS"
          />
          <label htmlFor="DETAILS">DETAILS</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setTRANSACTION_DATE(e.target.value)}
            value={TRANSACTION_DATE}
            placeholder="Enter TRANSACTION_DATE"
          />
          <label htmlFor="TRANSACTION_DATE">TRANSACTION_DATE</label>
        </div>

 <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
</div>
)
}
export default Addtransactions;
