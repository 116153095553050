import React from "react";
import Addretailers from "./addretailers";
import Updateretailers from "./updateretailers";
import Viewretailers from "./viewretailers";

const Retailers = () => {
  return (
    <div>
      <Addretailers />
      {/* <Updateretailers /> */}
      <Viewretailers />
    </div>
  );
};

export default Retailers;
