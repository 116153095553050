import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Updateadmin = () => {
  var rx = 0;
  console.log(rx);
  const { state } = useLocation();
  const [ADMIN_ID, setADMIN_ID] = useState(state[rx++]);
  const [ADMIN_NAME, setADMIN_NAME] = useState(state[rx++]);
  const [PASSWORD, setPASSWORD] = useState(state[rx++]);
  const [MOBILE, setMOBILE] = useState(state[rx++]);
  const [EMAIL, setEMAIL] = useState(state[rx++]);
  const [BALANCE, setBALANCE] = useState(state[rx++]);
  const submitdata = () => {
    const value = {
      ADMIN_ID: ADMIN_ID,
      ADMIN_NAME: ADMIN_NAME,
      PASSWORD: PASSWORD,
      MOBILE: MOBILE,
      EMAIL: EMAIL,
      BALANCE: BALANCE,
    };
    axios
      .post("http://202.170.204.109:5000/mobilerecharge/updateadmin", value)
      .then((response) => {
        window.location.replace("/viewadmin");
      });
  };
  return (
    <div>
      <h1>Add admin</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setADMIN_ID(e.target.value)}
          value={ADMIN_ID}
          placeholder="Enter ADMIN_ID"
        />
        <label htmlFor="ADMIN_ID">ADMIN_ID</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setADMIN_NAME(e.target.value)}
          value={ADMIN_NAME}
          placeholder="Enter ADMIN_NAME"
        />
        <label htmlFor="ADMIN_NAME">ADMIN_NAME</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setPASSWORD(e.target.value)}
          value={PASSWORD}
          placeholder="Enter PASSWORD"
        />
        <label htmlFor="PASSWORD">PASSWORD</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setMOBILE(e.target.value)}
          value={MOBILE}
          placeholder="Enter MOBILE"
        />
        <label htmlFor="MOBILE">MOBILE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setEMAIL(e.target.value)}
          value={EMAIL}
          placeholder="Enter EMAIL"
        />
        <label htmlFor="EMAIL">EMAIL</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setBALANCE(e.target.value)}
          value={BALANCE}
          placeholder="Enter BALANCE"
        />
        <label htmlFor="BALANCE">BALANCE</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Updateadmin;
