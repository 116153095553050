import axios from "axios";
import React, { useEffect, useState } from "react";

const Recharge = () => {
  const url = process.env.REACT_APP_SERVER_URL;
  const [type, setType] = useState("");
  const [mobile, setMobile] = useState("");
  const [amount, setAmount] = useState(0);
  const [network, setNetwork] = useState("");
  const [plan, setPlan] = useState("");
  const [detail, setDetail] = useState("");
  const [balance, setBalance] = useState("");
  const [rerender, setRerender] = useState(0);
  useEffect(() => {
    axios
      .post(url + "mobilerecharge/getBalance", {
        ID: window.localStorage.getItem("id"),
      })
      .then((response) => {
        setBalance(response.data[0]);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [rerender]);

  const networks = ["Airtel", "Jio", "Vodafone", "BSNL"];
  const providers = [
    "Sun Direct",
    "Airtel Digital TV",
    "Tata Play",
    "Videocon D2H",
    "DishTV",
  ];

  const recharge_codes = [
    {
      Airtel: "RA",
      Vodafone: "RV",
      Jio: "RJ",
    },
  ];

  const makeRecharge = () => {
    var userid = "5000552";
    var password = "Feb@2023";
    var authno = "9629738884";
    // var reqcommand = "RA9952363956A10";
    if (network === "Airtel Digital TV") {
      var reqcommand = `RG${mobile}A${amount}`;
    } else {
      var reqcommand = `R${network[0]}${mobile}A${amount}`;
    }
    console.log(reqcommand);
    const currentDateTime = new Date();

    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hour = String(currentDateTime.getHours()).padStart(2, "0");
    const minute = String(currentDateTime.getMinutes()).padStart(2, "0");
    const second = String(currentDateTime.getSeconds()).padStart(2, "0");
    const millisecond = String(currentDateTime.getMilliseconds()).padStart(
      3,
      "0"
    );
    const sourceref = `${year}${day}${hour}${minute}${second}${millisecond}`;
    console.log(sourceref);

    const formatter2 = new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
    var y = String(year).substring(2);
    const reqdate = `${day}/${month}/${y}`;
    console.log(reqdate);
    const reqtime = `${hour}:${minute}:${second}`;
    console.log(reqtime);
    var urllink = `http://202.170.204.109:92/MARSExinRequest?userid=${userid}&password=${password}&authno=${authno}&reqcommand=${reqcommand}&sourceref=${sourceref}&reqdate=${reqdate}&reqtime=${reqtime}`;
    console.log(urllink);

    if (parseInt(amount) <= 0) {
      alert("Choose a valid value for Amount to be recharged");
      return;
    }
    if (parseInt(amount) < 10 || parseInt(amount) > 5000) {
      alert("Choose a valid value for Amount to be recharged");
      return;
    }
    if (parseInt(balance) < parseInt(amount)) {
      alert("There is not enough balance with you!");
      return;
    }
    if (mobile.length === 0) {
      if (type === "DTH") {
        alert("Customer ID is required!");
        return;
      } else if (type === "Mobile") {
        alert("Mobile number is required!");
        return;
      }
    }
    if (network.length === 0) {
      if (type === "DTH") {
        alert("Provider must be chosen!");
        return;
      } else if (type === "Mobile") {
        alert("Network must be chosen!");
        return;
      }
    }
    const id = window.localStorage.getItem("id");
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "mobilerecharge/insertrechargehistory",
        {
          INITIATOR_ID: id,
          CUSTOMER_MOBILE: mobile,
          TYPE: type,
          AMOUNT: amount,
          PLAN: plan,
          detail: detail,
          ROLE: window.localStorage.getItem("role"),
          NETWORK: network,
          BALANCE: balance[0],
          URL: urllink,
          SOURCEREF: sourceref,
        }
      )
      .then((response) => {
        if (response.data !== "error") {
          alert("Recharge in Progress");
          setAmount(0);
          setMobile("");
          setType("");
          setNetwork("");
          setPlan("");
          setDetail("");
          setRerender(rerender + 1);
        }
        else
        {
          alert("Some error")
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "130px",
        border: "2px solid black",
        width: "30%",
        marginLeft: "35%",
        borderRadius: "25px",
        boxShadow: "10px 10px 5px lightblue",
      }}
    >
      <div style={{ margin: "20px" }}>
        <h4>Your current Balance is Rs. {balance}</h4>
      </div>
      <div className="d-flex gap-5 mb-4 align-items-center justify-content-center">
        <div className="d-flex gap-1">
          <label htmlFor="DTH">DTH</label>
          <input
            type="radio"
            value="DTH"
            id="DTH"
            name="type"
            checked={type === "DTH"}
            onChange={(e) => {
              setType(e.target.value);
            }}
          />
        </div>
        <div className="d-flex gap-1">
          <label htmlFor="mob">Mobile</label>
          <input
            type="radio"
            value="Mobile"
            id="mob"
            name="type"
            checked={type === "Mobile"}
            onChange={(e) => {
              setType(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="mb-4">
        {type === "Mobile" ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <label htmlFor="cust" style={{ fontSize: "17px" }}>
              Mobile:
            </label>
            <input
              type="tel"
              id="cust"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              style={{
                marginLeft: "10px",
                border: "none",
                outline: "none",
                border: "2px solid black",
                borderRadius: "15px",
                height: "40px",
                fontSize: "17px",
                paddingLeft: "10px",
                boxShadow: "10px 10px 5px lightblue",
              }}
            />
          </div>
        ) : type === "DTH" ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <label htmlFor="cust">Customer ID:</label>
            <input
              type="tel"
              id="cust"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              style={{
                marginLeft: "10px",
                border: "none",
                outline: "none",
                border: "2px solid black",
                borderRadius: "15px",
                height: "40px",
                fontSize: "17px",
                paddingLeft: "10px",
                boxShadow: "10px 10px 5px lightblue",
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {type === "Mobile" ? (
        <div className="mb-4">
          <label htmlFor="netw">Network:</label>
          <input
            list="list"
            type="text"
            id="netw"
            value={network}
            style={{
              marginLeft: "10px",
              border: "none",
              outline: "none",
              border: "2px solid black",
              borderRadius: "15px",
              height: "40px",
              fontSize: "17px",
              paddingLeft: "10px",
              boxShadow: "10px 10px 5px lightblue",
            }}
            onChange={(e) => {
              setNetwork(e.target.value);
            }}
          />
          <datalist id="list">
            {networks.map((network, index) => {
              return <option value={network} key={index} />;
            })}
          </datalist>
        </div>
      ) : type === "DTH" ? (
        <div className="mb-4">
          <label htmlFor="prov">Providers:</label>
          <input
            list="P"
            type="text"
            id="prov"
            style={{
              marginLeft: "10px",
              border: "none",
              outline: "none",
              border: "2px solid black",
              borderRadius: "15px",
              height: "40px",
              fontSize: "17px",
              paddingLeft: "10px",
              boxShadow: "10px 10px 5px lightblue",
            }}
            value={network}
            onChange={(e) => {
              setNetwork(e.target.value);
            }}
          />
          <datalist id="P">
            {providers.map((network, index) => {
              return <option value={network} key={index} />;
            })}
          </datalist>
        </div>
      ) : (
        <></>
      )}
      <div className="mb-4">
        <label htmlFor="amnt">Amount:</label>
        <input
          type="number"
          id="amnt"
          value={amount}
          style={{
            marginLeft: "10px",
            border: "none",
            outline: "none",
            border: "2px solid black",
            borderRadius: "15px",
            height: "40px",
            fontSize: "17px",
            paddingLeft: "10px",
            boxShadow: "10px 10px 5px lightblue",
          }}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </div>
      <div>
        <button
          className="btn"
          type="submit"
          style={{
            marginBottom: "20px",
            border: "2px solid black",
            boxShadow: "5px 5px 5px lightblue",
          }}
          onClick={makeRecharge}
        >
          Make Recharge
        </button>
      </div>
    </div>
  );
};

export default Recharge;
