import React from "react";
import Adddistributors from "./adddistributors";
import Updatedistributors from "./updatedistributors";
import Viewdistributors from "./viewdistributors";

const Distributors = () => {
  return (
    <div>
      <Adddistributors />
      {/* <Updatedistributors /> */}
      <Viewdistributors />
    </div>
  );
};

export default Distributors;
