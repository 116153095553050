import React from "react";
import "./Dashboard.css";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
const Dashboard = () => {
  return (
    <>
      {/* <Navbar /> */}
      <h1>Welcome!</h1>
      {/* <div className="card-container">
        <div className="my-card">
          <h1 className="title">recharge</h1>
          <div className="icon"></div>
          <div className="button">
            <NavLink to="/recharge">Recharge</NavLink>
          </div>
        </div>
        <div className="my-card">
          <h1 className="title">Distributors</h1>
          <div className="icon"></div>
          <div className="button">
            <NavLink to="/distributor">Distributors</NavLink>
          </div>
        </div>
        <div className="my-card">
          <h1 className="title">retailers</h1>
          <div className="icon"></div>
          <div className="button">
            <NavLink to="/retailer">Retailers</NavLink>
          </div>
        </div>
        <div className="my-card">
          <h1 className="title">admin</h1>
          <div className="icon"></div>
          <div className="button"></div>
          <NavLink to="/admin">Admins</NavLink>
        </div>
        <div className="my-card">
          <h1 className="title">transfer</h1>
          <div className="icon"></div>
          <div className="button">
            <NavLink to="/transfer">Transfer</NavLink>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
