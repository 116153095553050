import axios from "axios";
import { useState } from "react";
const Adddistributors = () => {
  const [DISTRIBUTOR_NAME, setDISTRIBUTOR_NAME] = useState("");
  const [PROFIT, setPROFIT] = useState("");
  const [PASSWORD, setPASSWORD] = useState("");
  const [MOBILE, setMOBILE] = useState("");
  const [EMAIL, setEMAIL] = useState("");
  const [BALANCE, setBALANCE] = useState("");
  const [LOCATION, setLOCATION] = useState("");
  const submitdata = () => {
    if (MOBILE.length !== 10) {
      alert("Please enter a valid mobile number with 10 digits");
      return;
    }
    if (DISTRIBUTOR_NAME.length === 0) {
      alert("Name field is empty!");
      return;
    }
    if (PASSWORD.length === 0) {
      alert("password field is empty!");
      return;
    }
    if (MOBILE.length === 0) {
      alert("Mobile field is empty!");
      return;
    }
    if (LOCATION.length === 0) {
      alert("Address field is empty!");
      return;
    }
    const value = {
      USER_NAME: DISTRIBUTOR_NAME,
      PROFIT: 0,
      PASSWORD: PASSWORD,
      MOBILE: MOBILE,
      EMAIL: EMAIL,
      BALANCE: 0,
      LOCATION: LOCATION,
    };
    axios
      .post("http://202.170.204.109:5000/mobilerecharge/insertdistributors", value)
      .then((response) => {
        if (response.data === "e") {
          alert("success");
          setDISTRIBUTOR_NAME("");
          setPROFIT("");
          setPASSWORD("");
          setMOBILE("");
          setEMAIL("");
          setBALANCE("");
          setLOCATION("");
        }
      })
      .catch((err) => {
        alert("Failed");
        console.log(err.message);
      });
  };
  return (
    <div>
      <h1>Add distributors</h1>
      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setDISTRIBUTOR_NAME(e.target.value)}
          value={DISTRIBUTOR_NAME}
          placeholder="Enter DISTRIBUTOR_NAME"
        />
        <label htmlFor="DISTRIBUTOR_NAME">DISTRIBUTOR_NAME</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPROFIT(e.target.value)}
            value={PROFIT}
            placeholder="Enter PROFIT"
          />
          <label htmlFor="PROFIT">PROFIT</label>
        </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setPASSWORD(e.target.value)}
          value={PASSWORD}
          placeholder="Enter PASSWORD"
        />
        <label htmlFor="PASSWORD">PASSWORD</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setMOBILE(e.target.value)}
          value={MOBILE}
          size={10}
          placeholder="Enter MOBILE"
        />
        <label htmlFor="MOBILE">MOBILE</label>
      </div>

      <div className="form-floating mb-3 mt-3">
        <input
          type="email"
          className="form-control"
          onChange={(e) => setEMAIL(e.target.value)}
          value={EMAIL}
          placeholder="Enter EMAIL"
        />
        <label htmlFor="EMAIL">EMAIL</label>
      </div>

      {/* <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setBALANCE(e.target.value)}
          value={BALANCE}
          placeholder="Enter BALANCE"
        />
        <label htmlFor="BALANCE">BALANCE</label>
      </div> */}

      <div className="form-floating mb-3 mt-3">
        <input
          type="text"
          className="form-control"
          onChange={(e) => setLOCATION(e.target.value)}
          value={LOCATION}
          placeholder="Enter LOCATION"
        />
        <label htmlFor="LOCATION">ADDRESS</label>
      </div>

      <input
        type="submit"
        className="btn btn-primary"
        onClick={submitdata}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default Adddistributors;
