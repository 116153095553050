import React from "react";
import Viewadmin from "./viewadmin";
import Addadmin from "./addadmin";
import Updateadmin from "./updateadmin";
const Admin = () => {
  return (
    <div>
      {/* <Addadmin /> */}
      {/* <Updateadmin /> */}
      <Viewadmin />
    </div>
  );
};

export default Admin;
