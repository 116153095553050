import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const nav = useNavigate();
  return (
    <div>
      <nav className="navbar navbar-expand-sm bg-dark navbar-dark justify-content-center">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  className="text-white text-decoration-none  m-2"
                >
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/recharge"
                  className="text-white text-decoration-none  m-2m-2"
                >
                  Recharge
                </NavLink>
              </li>
              {window.localStorage.getItem("role") !== "Retailer" ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/transfer"
                      className="text-white text-decoration-none  m-2"
                    >
                      Transfer
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/retailer"
                      className="text-white text-decoration-none  m-2"
                    >
                      Retailers
                    </NavLink>
                  </li>
                </>
              ) : (
                <></>
              )}
              {window.localStorage.getItem("role") === "Admin" ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/distributor"
                      className="text-white text-decoration-none  m-2"
                    >
                      Distributors
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/admin"
                      className="text-white text-decoration-none  m-2"
                    >
                      Admins
                    </NavLink>
                  </li>
                </>
              ) : (
                <></>
              )}
              {window.localStorage.getItem("role") !== "Retailer" ? (
                <li className="nav-item">
                  <NavLink
                    to="/reports"
                    className="text-white text-decoration-none  m-2"
                  >
                    Reports
                  </NavLink>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>

        <button
          className="btn btn-danger m-3 "
          onClick={() => {
            window.localStorage.removeItem("id");
            window.localStorage.removeItem("role");
            window.localStorage.removeItem("balance");
            nav("/");
          }}
        >
          LOGOUT
        </button>
      </nav>
    </div>
  );
};

export default Navbar;
