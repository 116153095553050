import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = ({login}) => {
  const nav = useNavigate();
  useEffect(() => {
    if (window.localStorage.getItem("id") !== null) {
      nav("/dashboard");
    }
  }, []);

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  

  

  return (
    <div>
      <div className="container mt-3">
        <form action="/action_page.php">
          <div className="form-floating mb-3 mt-3">
            <input
              type="tel"
              className="form-control"
              id="email"
              placeholder="Enter Mobile Number"
              name="email"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
            <label htmlFor="email">Mobile</label>
          </div>
          <div className="form-floating mt-3 mb-3">
            <input
              type="password"
              className="form-control"
              id="pwd"
              placeholder="Enter password"
              name="pswd"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <label htmlFor="pwd">Password</label>
          </div>
          <div className="d-flex gap-5 mb-3 align-items-center justify-content-center">
            <div className="d-flex gap-1">
              <label htmlFor="adminRole">Admin</label>
              <input
                type="radio"
                value="Admin"
                id="adminRole"
                name="role"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              />
            </div>
            <div className="d-flex gap-1">
              <label htmlFor="distRole">Distributor</label>
              <input
                type="radio"
                value="Distributor"
                id="distRole"
                name="role"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              />
            </div>
            <div className="d-flex gap-1">
              <label htmlFor="retRole">Retailer</label>
              <input
                type="radio"
                value="Retailer"
                id="retRole"
                name="role"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => login(e,mobile,password,role)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
