import React from "react";
import { NavLink } from "react-router-dom";

const ReportsNav = () => {
  return (
    <>
      <div>
        <button className="btn btn-dark m-3">
          <NavLink
            to="/paymentreports"
            className="text-decoration-none text-white"
          >
            Payment Reports
          </NavLink>
        </button>
        <button className="btn btn-primary m-3">
          <NavLink to="/statement" className="text-decoration-none text-white">
            Account Statement
          </NavLink>
        </button>
        <button className="btn btn-danger  m-3">
          <NavLink to="/success" className="text-decoration-none text-white">
            Success Reports
          </NavLink>
        </button>
        <button className="btn btn-secondary  m-3">
          <NavLink to="/failures" className="text-decoration-none text-white">
            Failure Reports
          </NavLink>
        </button>
        <button className="btn btn-success m-3">
          <NavLink to="/suspenses" className="text-decoration-none text-white">
            Suspense Reports
          </NavLink>
        </button>
      </div>
    </>
  );
};

export default ReportsNav;
