import "./App.css";
import Routing from "./routing";

function App() {
  return (
    <>
      {/* <Login /> */}
      
      <Routing />
    </>
  );
}

export default App;
