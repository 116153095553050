import Updateadmin from "./updateadmin";
import Viewadmin from "./viewadmin";
import Addadmin from "./addadmin";
import Updatedistributors from "./updatedistributors";
import Viewdistributors from "./viewdistributors";
import Adddistributors from "./adddistributors";
import Updaterechargehistory from "./updaterechargehistory";
import Viewrechargehistory from "./viewrechargehistory";
import Addrechargehistory from "./addrechargehistory";
import Updateretailers from "./updateretailers";
import Viewretailers from "./viewretailers";
import Addretailers from "./addretailers";
import Updatetransactions from "./updatetransactions";
import Viewtransactions from "./viewtransactions";
import Addtransactions from "./addtransactions";
import Dashboard from "./Dashboard";
import { Route, Routes, useNavigate } from "react-router-dom";

import React, { useState } from "react";
import Admin from "./Admin";
import Retailers from "./Retailers";
import Distributors from "./Distributors";
import Transfer from "./Transfer";
import Recharge from "./Recharge";
import Login from "./login";
import Reports from "./Reports";
import PaymentReports from "./PaymentReports";
import AccountStatement from "./AccountStatement";
import SuccessReports from "./SuccessReports";
import FailureReports from "./FailureReports";
import SuspenseReports from "./SuspenseReports";
import Navbar from "./Navbar";
import axios from "axios";

const Routing = () => {
  const [x,setx]=useState(0)
  const url = process.env.REACT_APP_SERVER_URL;
  const nav = useNavigate();
  const login = (e,mobile,password,role) => {
    e.preventDefault();
    var data=url + "mobilerecharge/login"
    console.log(data)
    axios
      .post(data, {
        MOBILE: mobile,
        PASSWORD: password,
        ROLE: role,
      })
      .then((response) => {
        
        if (response.data.message === "success") {
          window.localStorage.setItem("id", response.data.id);
          window.localStorage.setItem("role", role);
          
          alert("Logged in successfully!");
          setx(1)
          nav("/dashboard");
        }
        else
        {
          alert("wrong username and password")
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      {window.localStorage.getItem("id") !== null ? <Navbar /> : ""}
      <Routes>
        <Route path="/updateadmin" element={<Updateadmin />} />
        <Route path="/viewadmin" element={<Viewadmin />} />
        <Route path="/addadmin" element={<Addadmin />} />
        <Route path="/updatedistributors" element={<Updatedistributors />} />
        <Route path="/viewdistributors" element={<Viewdistributors />} />
        <Route path="/adddistributors" element={<Adddistributors />} />
        <Route
          path="/updaterechargehistory"
          element={<Updaterechargehistory />}
        />
        <Route path="/viewrechargehistory" element={<Viewrechargehistory />} />
        <Route path="/addrechargehistory" element={<Addrechargehistory />} />
        <Route path="/updateretailers" element={<Updateretailers />} />
        <Route path="/viewretailers" element={<Viewretailers />} />
        <Route path="/addretailers" element={<Addretailers />} />
        <Route path="/updatetransactions" element={<Updatetransactions />} />
        <Route path="/viewtransactions" element={<Viewtransactions />} />
        <Route path="/addtransactions" element={<Addtransactions />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/retailer" element={<Retailers />} />
        <Route path="/distributor" element={<Distributors />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/recharge" element={<Recharge />} />
        <Route path="/" element={<Login login={login}/>} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/paymentreports" element={<PaymentReports />} />
        <Route path="/statement" element={<AccountStatement />} />
        <Route path="/success" element={<SuccessReports />} />
        <Route path="/failures" element={<FailureReports />} />
        <Route path="/suspenses" element={<SuspenseReports />} />
      </Routes>
    </>
  );
};

export default Routing;
