import React, { useEffect, useState } from "react";
import axios from "axios";
import ReportsNav from "./ReportsNav";
// import Filters from "./Filters";
const AccountStatement = () => {
  const [transactions, setTransactions] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "mobilerecharge/accountstatement"
      )
      .then((response) => {
        console.log(response.data);
        setTransactions(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const searchdata = (e) => {
    const r = [];

    for (var k of data) {
      var v = 0;

      for (var n of k) {
        n = "" + n;
        if (n.toLowerCase().indexOf(e) !== -1) {
          v = 1;
          break;
        }
      }
      if (v === 1) {
        r.push(k);
      }
    }
    setTransactions(r);
  };

  return (
    <div>
      <ReportsNav />

      <h1>Account Statement</h1>
      <input
        type="search"
        onChange={(e) => {
          searchdata(e.target.value);
        }}
        className="form-select"
        placeholder="Search"
      />
      <table className="table">
        <thead>
          <tr>
            <th>RECHARGE ID</th>
            <th>INITIATOR</th>
            {/* <th>TYPE</th> */}
            <th>MOB/CUID</th>
            <th>AMOUNT</th>
            <th>NETWORK</th>
            <th>STATUS</th>
            <th>TRANSACTION DATE-TIME</th>{" "}
          </tr>
        </thead>
        <tbody>
          {transactions &&
            transactions.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td>{transaction[0]}</td>
                  <td>{transaction[10] + " {" + transaction[11] + "}"}</td>
                  {/* <td>{transaction[3]}</td> */}
                  <td>{transaction[2]}</td>
                  <td>{transaction[4]}</td>
                  <td>{transaction[7]}</td>
                  <td>
                    {transaction[6] === "INITIATED"
                      ? "Suspense"
                      : transaction[6]}
                  </td>
                  <td>{transaction[5]}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AccountStatement;
