
import axios from "axios";
import { useState } from "react";
const Addadmin = () => {
const [ADMIN_NAME, setADMIN_NAME] = useState('');
const [PASSWORD, setPASSWORD] = useState('');
const [MOBILE, setMOBILE] = useState('');
const [EMAIL, setEMAIL] = useState('');
const [BALANCE, setBALANCE] = useState('');
const submitdata = () => {
 const value={ADMIN_NAME:ADMIN_NAME,PASSWORD:PASSWORD,MOBILE:MOBILE,EMAIL:EMAIL,BALANCE:BALANCE};
axios.post("http://202.170.204.109:5000/mobilerecharge/insertadmin", value);
alert("success")
setADMIN_NAME('');
setPASSWORD('');
setMOBILE('');
setEMAIL('');
setBALANCE('');
};
return (
<div>
    <h1>Add admin</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setADMIN_NAME(e.target.value)}
            value={ADMIN_NAME}
            placeholder="Enter ADMIN_NAME"
          />
          <label htmlFor="ADMIN_NAME">ADMIN_NAME</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPASSWORD(e.target.value)}
            value={PASSWORD}
            placeholder="Enter PASSWORD"
          />
          <label htmlFor="PASSWORD">PASSWORD</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setMOBILE(e.target.value)}
            value={MOBILE}
            placeholder="Enter MOBILE"
          />
          <label htmlFor="MOBILE">MOBILE</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setEMAIL(e.target.value)}
            value={EMAIL}
            placeholder="Enter EMAIL"
          />
          <label htmlFor="EMAIL">EMAIL</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setBALANCE(e.target.value)}
            value={BALANCE}
            placeholder="Enter BALANCE"
          />
          <label htmlFor="BALANCE">BALANCE</label>
        </div>

 <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
</div>
)
}
export default Addadmin;
