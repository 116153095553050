import React, { useEffect, useState } from "react";
import axios from "axios";
const Transfer = () => {
  const url = process.env.REACT_APP_SERVER_URL;
  const [retailers, setRetailers] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [amount, setAmount] = useState(0);
  const [reciever, setReciever] = useState("");
  const [recieverBalance, setRecieverBalance] = useState("");
  const [recipient, setRecipient] = useState("");
  const [balance, setBalance] = useState("");
  const [rerender, setRerender] = useState(0);
  useEffect(() => {
    axios
      .post(url + "mobilerecharge/viewinitiators")
      .then((response) => {
        setRetailers(response.data.retailers);
        setDistributors(response.data.distributors);
      })
      .catch((error) => {
        console.log(error.message);
      });
    axios
      .post(url + "mobilerecharge/getBalance", {
        ID: window.localStorage.getItem("id"),
      })
      .then((response) => {
        setBalance(response.data[0]);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [rerender]);

  const transfer = () => {
    if (parseInt(amount) <= 0) {
      alert("Choose a valid value for Amount to be transferred");
      return;
    }
    if (reciever.length === 0) {
      alert("Choose a recipient to transfer");
      return;
    }
    if (parseInt(balance) < parseInt(amount)) {
      alert("There is not enough balance with you!");
      return;
    }
    axios
      .post(url + "mobilerecharge/inserttransactions", {
        AMOUNT: amount,
        RECIEVER_ID: reciever,
        SENDER_ID: window.localStorage.getItem("id"),
        PREVIOUS_BALANCE: recieverBalance,
        RECIPIENT: recipient,
      })
      .then((response) => {
        if (response.data === "Success") {
          alert("Amount transferred successfully");
          setAmount("");
          setReciever("");
          setRecieverBalance("");
          setRecipient("");
          setRerender(rerender + 1);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "10%",
        border: "2px solid #000",
        width: "30%",
        marginLeft: "35%",
        borderRadius: "25px",
        boxShadow: "10px 10px 5px lightblue",
      }}
    >
      <div style={{ margin: "20px" }}>
        <h4>Your current Balance is Rs. {balance}</h4>
      </div>
      <div className="d-flex gap-5 mb-3 align-items-center justify-content-center">
        {window.localStorage.getItem("role") === "Admin" ? (
          <div className="d-flex gap-1">
            <label htmlFor="Distributor" style={{ fontSize: "17px" }}>
              Distributor
            </label>
            <input
              type="radio"
              value="Distributor"
              id="Distributor"
              name="type"
              checked={recipient === "Distributor"}
              onChange={(e) => {
                setRecipient(e.target.value);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex gap-1">
          <label htmlFor="ret" style={{ fontSize: "17px" }}>
            Retailer
          </label>
          <input
            type="radio"
            value="Retailer"
            id="ret"
            name="type"
            checked={recipient === "Retailer"}
            onChange={(e) => {
              setRecipient(e.target.value);
            }}
          />
        </div>
      </div>
      {recipient === "Retailer" ? (
        <>
          <div>
            <label htmlFor="ret">Recipient:</label>
            <input
              list="reta"
              name="ret"
              id="ret"
              style={{
                marginLeft: "10px",
                border: "none",
                outline: "none",
                border: "2px solid black",
                borderRadius: "15px",
                height: "40px",
                fontSize: "17px",
                paddingLeft: "10px",
                boxShadow: "10px 10px 5px lightblue",
              }}
              onChange={(e) => {
                let rec = retailers.find(
                  (retailer) => retailer[5] === e.target.value
                );
                setReciever(rec[0]);
                setRecieverBalance(rec[6]);
              }}
            />
            <datalist id="reta">
              {retailers.map((retailer, index) => {
                return <option value={retailer[5]} key={index} />;
              })}
            </datalist>
          </div>
        </>
      ) : recipient === "Distributor" ? (
        <>
          <div
            style={
              {
                // marginTop: "20px",
              }
            }
          >
            <label htmlFor="distribu">Recipient:</label>
            <input
              list="distrib"
              name="distribu"
              style={{
                marginLeft: "10px",
                border: "none",
                outline: "none",
                border: "2px solid black",
                borderRadius: "15px",
                height: "40px",
                fontSize: "17px",
                paddingLeft: "10px",
                boxShadow: "10px 10px 5px lightblue",
              }}
              id="distribu"
              onChange={(e) => {
                let rec = distributors.find(
                  (distributor) => distributor[5] === e.target.value
                );
                setReciever(rec[0]);
                setRecieverBalance(rec[6]);
              }}
            />
            <datalist id="distrib">
              {distributors.map((distributor, index) => {
                return <option value={distributor[5]} key={index} />;
              })}
            </datalist>
          </div>
        </>
      ) : (
        <></>
      )}
      {recieverBalance && (
        <p
          style={{
            marginTop: "30px",
            fontSize: "17px",
          }}
        >
          Recipient Balance: Rs. <b>{recieverBalance}</b>
        </p>
      )}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <label htmlFor="amnt">Amount:</label>
        <input
          type="number"
          id="amnt"
          style={{
            marginLeft: "10px",
            border: "none",
            outline: "none",
            border: "2px solid black",
            borderRadius: "15px",
            height: "40px",
            fontSize: "17px",
            paddingLeft: "10px",
            marginBottom: "20px",
            boxShadow: "10px 10px 5px lightblue",
          }}
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </div>
      {/* <div>
        <label htmlFor="decs">Description:</label>
        <textarea
          id="decs"
          cols="40"
          rows="10"
          value={detail}
          onChange={(e) => {
            setDetail(e.target.value);
          }}
        ></textarea>
      </div> */}
      <div>
        <button
          className="btn"
          style={{
            marginBottom: "20px",
            border: "2px solid black",
            boxShadow: "5px 5px 5px lightblue",
          }}
          type="submit"
          onClick={transfer}
        >
          Send Money
        </button>
      </div>
    </div>
  );
};

export default Transfer;
