import React from "react";
import { NavLink } from "react-router-dom";
import ReportsNav from "./ReportsNav";

const Reports = () => {
  return (
    <div>
      <ReportsNav />
    </div>
  );
};

export default Reports;
